<template>
  <div class="upload-container">
    <el-upload
      :data="qiNiuParams"
      :multiple="false"
      :show-file-list="false"
      ref="uploadSingle"
      :on-success="handleImageSuccess"
      class="image-uploader"
      drag
      :before-upload="beforeUpload"
      action="https://upload-z1.qiniup.com"
      :accept="accept"
    >
      <slot v-if="!(imageUrl && imageUrl.length > 0)">
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </slot>
      <div v-else class="image-preview">
        <div v-show="imageUrl.length > 1" class="image-preview-wrapper">
          <img :src="imageUrl" />
        </div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { getQNToken } from '@/api/qiniu'
import { getFileName, getTimeRandom, random_string } from '@/utils/utils'
import { getFileUrlPvt } from '@/api/common'

export default {
  name: 'SingleImageUpload',
  props: {
    value: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '*'
    },
    isclear: {
      type: Boolean,
      default: false
    },
    isPublic: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      tempUrl: '',
      method: 'post',
      qiNiuParams: {
        token: '',
        key: ''
      },
      requestHeaders: {},
      fileSrc: '',
      licensePreviewShow: false,
      domainUrl: '',
      currentFileSrc: '',
      imageUrl: ''
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.handleImageSuccess()
      }
    },
    isclear(){
      if(this.isclear){
        this.imageUrl = "";
      }
    }
  },
  methods: {
    rmImage() {
      this.emitInput('')
    },
    emitInput(val) {
      this.$emit('input', val)
    },
    handleShowImage() {
      getFileUrlPvt({
        file_key: this.value,
        format: 'imageView2/1/w/200/h/200'
      }).then((res) => {
        this.imageUrl = res.data.file_url
      })
    },
    handleImageSuccess(e) {
      const { key } = e || {}
      if (key) {
        this.emitInput(key)
        this.$nextTick(() => {
          this.handleShowImage()
        })
      }
    },

    beforeUpload(file) {
      const _self = this
      return new Promise((resolve, reject) => {
        let params = {
          upload_type: 1,
          format: 'imageView2/1/w/200/h/200'
        }
        if(this.isPublic) params.is_pvt = 1
        getQNToken(params)
          .then((data) => {
            let filename = file.name
            let fileSuffix = getFileName(filename)
            let random = getTimeRandom() + random_string()
            let randomName = `${random}${fileSuffix}`
            let key = data.data.dir + randomName
            _self.qiNiuParams.token = data.data.token
            _self.qiNiuParams.key = data.data.config.bucket + '/' + key
            resolve(true)
          })
          .catch((err) => {
            console.log(err)
            reject(false)
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container {
  width: 100%;
  position: relative;
  /deep/ {
    .el-upload-dragger {
      width: 168px;
      height: 106px;
      background: #f5f6fa;
      border-radius: 5px;
      font-size: 12px;
      .el-icon-upload {
        font-size: 20px;
        line-height: 12px;
        margin-top: 31px;
        margin-bottom: 15px;
      }
      .el-upload__text {
        font-size: 12px;
        color: rgba(51, 51, 48, 0.5);
      }
    }
  }
  .image-preview {
    width: 168px;
    height: 106px;
    position: relative;
    border: 1px dashed #d9d9d9;
    .image-preview-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .image-preview-action {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      cursor: default;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.3s;
      cursor: pointer;
      text-align: center;
      line-height: 106px;
      .el-icon-delete {
        font-size: 36px;
      }
    }
    &:hover {
      .image-preview-action {
        opacity: 1;
      }
    }
  }
}
</style>
