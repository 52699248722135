<template>
  <div class="container_letter" v-show="showLetter">
    <div class="letter_dialog">
      <div class="letter_content">
        <img src="~@/assets/image/letter_close.png" alt="" class="close_btn" @click="closeLetter">
        <span class="guide_btn" @click="gobackHome">寻找想要的素材</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    showLetter:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{

    }
  },
  methods:{
    /**
     * 点击关闭信封
     */
    closeLetter(){
      this.$emit('closeLetterDialog', false)
    },
    /**
     * 点击寻找想要的素材返回首页
     */
    gobackHome(){
      this.$router.push({ path: '/playList'});
    }
  }
}
</script>

<style lang="scss" scoped>
.container_letter{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2999;
  .letter_dialog{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .letter_content{
      width: 100%;
      height: 95%;
      max-width: 620px;
      max-height: 720px;
      background: url('../../../assets/image/letter-new.png') no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      position: relative;
      .close_btn{
        width: 30px;
        height: 30px;
        position: absolute;
        right: -50px;
        top: -15px;
        z-index: 2000;
        cursor: pointer;
      }
      .guide_btn{
        display: inline-block;
        width: 176px;
        height: 40px;
        background: linear-gradient(270deg, #FBDDB5 0%, #FFD073 100%);
        border-radius: 20px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #7F4300;
        line-height: 40px;
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2000;
        cursor: pointer;
        text-align: center;
      }
    }
  }
}
</style>
