import request from '@/utils/request'

/**
 * 获取七牛云配置
 * 订阅私有库转移 获取上传token的地方 直传私有桶的加传字段is_pvt =1(对公回执单) 默认0公共桶
 * format: 'imageView2/1/w/200/h/200', 这块图片裁剪传参，接口不好统一处理
 * 2021-12-17 17:18:20
 * @author SnowRock
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getQNToken(params) {
  return request.post('/fontend/upload/config', params)
}
