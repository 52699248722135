<template>
  <!-- 双十一活动 -->
  <div v-loading="loading" class="camping-container">
    <h4 class="page-title">
      <img src="./image/txticon.png" />
      <span>618年中大促</span>
      <img src="./image/txticon.png" />
    </h4>
    <aside class="page-sub-title">
      <img src="./image/subtitle-618.png" />
      <img class="star" src="./image/star.png" />
      <img class="asideicon" src="./image/asideicon.png" />
    </aside>
    <div class="downbox">
      <img src="./image/time-icon.png" />
      <span class="downtext">倒计时：</span>
      <count-down
        :startTime="startTime"
        :endTime="endTime"
        dayTxt="天"
        hourTxt="时"
        minutesTxt="分"
        secondsTxt="秒" />
    </div>
    <div class="remaining">今日剩余名额：<span>{{5 || surplusNum}}</span></div>
    <h4 class="set-title">
      <img src="./image/txticon.png" />
      <span>企业会员</span>
      <img src="./image/txticon.png" />
    </h4>
    <div class="paybox">
      <div class="payitem" :class="{'defaultActive':companyIndex===idx+1}" @click="setViptype(item,idx)" v-for="(item,idx) in companyGoodList" :key="idx">
        <div class="paylist">
          <p class="payname">
            <img v-if="idx===0" src="./image/payname1.png" />
            <img v-if="idx===1" src="./image/payname2.png" />
            <img v-if="idx===2" src="./image/payname3.png" />
          </p>
          <div class="paystar" v-if="idx!==2">
            <img src="./image/paystar.png" />
          </div>
          <div class="paystar2" v-if="idx!==2">
            <img src="./image/paystar2.png" />
          </div>
          <div class="paystar3" v-if="idx===2">
            <img src="./image/default.png" />
          </div>
          <div class="tipbox" v-if="idx===2">推荐</div>
          <div class="old-price">
            <span class="name">原价：</span>
            <span class="price">{{item.origin_price}}元</span>
          </div>
          <div class="current_price">
            <span class="icon">¥&nbsp;</span>
            <span>{{item.price}}</span>
          </div>
          <div class="downloadcount">下载次数：{{item.down_count == 'n' ? '不限':item.down_count}}</div>
          <div class="daydown">单日下载限制：{{item.down_num}}</div>
          <div class="youhui">* 有效期限：加赠{{idx==0 ? '1个月' : idx==1 ? '半年' : '1年'}}</div>
        </div>
      </div>
    </div>
    <div class="paytype" v-if="companyIndex" :class="`choose-index-${companyIndex-1}`">
      <div class="payconter">
        <div class="paytabs">
          <span class="pay_comp" :class="{'active': paytype=='online'}" @click="changePay('online')">
            <img v-if="paytype=='online'" src="../../../assets/image/pay_per_active.png" />
            <img v-else src="../../../assets/image/pay_per.png" />
            <label>在线支付</label>
          </span>
          <span class="pay_comp" :class="{'active': paytype=='public'}" @click="changePay('public')">
            <img v-if="paytype=='public'" src="../../../assets/image/pay_comp_active.png" />
            <img v-else src="../../../assets/image/pay_comp.png" />
            <label>对公转账</label>
          </span>
        </div>
        <div class="payqrcode" v-if="paytype=='online'">
          <div class="paytitle">
            <img src="./image/pay-title-icon.png" />
            <span class="title">在线支付 立即抢购</span>
            <img src="./image/pay-title-icon.png" />
          </div>
          <div class="qrcodebox">
            <aside class="aside">
              <div class="qrcode">
                <vue-qrcode v-if="wxPayQrCode" :value="wxPayQrCode" :width="156" :height="156" :margin="0" />
              </div>
              <div class="payload">
                <img src="./image/wechat.png" />
                <span>微信支付</span>
              </div>
            </aside>
            <aside class="aside">
              <div class="qrcode">
                <iframe
                  :src="aliPayQrCode"
                  frameborder="0"
                  width="156"
                  height="156"
                  transform= "scale(.5, .5)"
                  scrolling="no" />
              </div>
              <div class="payload">
                <img src="./image/ali.png" />
                <span>支付宝支付</span>
              </div>
            </aside>
          </div>
        </div>
        <div class="payqrcode" v-else>
          <div class="paytitle paypub">
            <img src="./image/pay-title-icon.png" />
            <span class="title">在线支付 立即抢购</span>
            <img src="./image/pay-title-icon.png" />
          </div>
          <ul class="payli">
            <li>
              <span class="name">账户名称：</span>
              <span class="label">北京数音未来科技有限公司</span>
            </li>
            <li>
              <span class="name">公司账号：</span>
              <span class="label">110060635018800064856</span>
            </li>
          </ul>
          <ul class="payli">
            <li>
              <span class="name">开户行：</span>
              <span class="label">交通银行股份有限公司北京三元支行</span>
            </li>
            <li>
              <span class="name">联系电话：</span>
              <span class="label">13522352793</span>
            </li>
          </ul>
          <div class="payfom">
            <el-form ref="publicForm" :model="publicForm" :rules="publicRules">
              <el-form-item label="付款回执单：" prop="receipt">
                <SingleImageUpload v-model="publicForm.receipt" accept="image/*"  :isclear="isclear" :isPublic="true"/>
              </el-form-item>
              <el-form-item label="付款备注 ：" prop="remark">
                <div class="remark">
                  <el-input v-model.trim="publicForm.remark" />
                  <el-button @click="handleSubmitPublicInfo">提交</el-button>
                </div>
              </el-form-item>
            </el-form>
            <p class="form-tips">完成线下转账/汇款后，请 <span @click="qimoChatClick">联系客服</span> ，我们将及时为您开通会员权限</p>
          </div>
        </div>
        <div class="payimg">
          <img src="./image/ld.png" />
        </div>
      </div>
    </div>
    <h4 class="set-title">
      <img src="./image/txticon.png" />
      <span>个人会员 (非商用）</span>
      <img src="./image/txticon.png" />
    </h4>
    <!-- geren -->
    <div class="paybox">
      <div class="payitem" :class="{'defaultActive':personIndex===idx+1}" @click="setPersionViptype(item,idx)" v-for="(item,idx) in personGoodList" :key="idx">
        <div class="paylist">
          <p class="payname">
            <img v-if="idx===0" src="./image/pmonth1.png" />
            <img v-if="idx===1" src="./image/pmonth2.png" />
            <img v-if="idx===2" src="./image/pmonth3.png" />
          </p>
          <div class="paystar" v-if="idx!==2">
            <img src="./image/paystar.png" />
          </div>
          <div class="paystar2" v-if="idx!==2">
            <img src="./image/paystar2.png" />
          </div>
          <div class="paystar3" v-if="idx===2">
            <img src="./image/default.png" />
          </div>
          <div class="tipbox" v-if="idx===2">推荐</div>
          <div class="old-price">
            <span class="name">原价：</span>
            <span class="price">{{item.origin_price}}元</span>
          </div>
          <div class="current_price">
            <span class="icon">¥&nbsp;</span>
            <span>{{item.price}}</span>
          </div>
          <div class="downloadcount">下载次数：{{item.down_count == 'n' ? '不限':item.down_count}}</div>
          <div class="daydown">单日下载限制：{{item.down_num}}</div>
          <div class="youhui">* 有效期限：加赠{{idx==0 ? '1个月' : idx==1 ? '半年' : '1年'}}</div>
        </div>
      </div>
    </div>
    <div class="paytype" v-if="personIndex" :class="`choose-index-${personIndex-1}`">
      <div class="payconter">
        <div class="paytabs">
          <span class="pay_comp" :class="{'active': paytype=='online'}" @click="changePay('online')">
            <img v-if="paytype=='online'" src="../../../assets/image/pay_per_active.png" />
            <img v-else src="../../../assets/image/pay_per.png" />
            <label>在线支付</label>
          </span>
          <span class="pay_comp" :class="{'active': paytype=='public'}" @click="changePay('public')">
            <img v-if="paytype=='public'" src="../../../assets/image/pay_comp_active.png" />
            <img v-else src="../../../assets/image/pay_comp.png" />
            <label>对公转账</label>
          </span>
        </div>
        <div class="payqrcode" v-if="paytype=='online'">
          <div class="paytitle">
            <img src="./image/pay-title-icon.png" />
            <span class="title">在线支付 立即抢购</span>
            <img src="./image/pay-title-icon.png" />
          </div>
          <div class="qrcodebox">
            <aside class="aside">
              <div class="qrcode">
                <vue-qrcode v-if="wxPayQrCode" :value="wxPayQrCode" :width="156" :height="156" :margin="0" />
              </div>
              <div class="payload">
                <img src="./image/wechat.png" />
                <span>微信支付</span>
              </div>
            </aside>
            <aside class="aside">
              <div class="qrcode">
                <iframe
                  :src="aliPayQrCode"
                  frameborder="0"
                  width="156"
                  height="156"
                  transform= "scale(.5, .5)"
                  scrolling="no" />
              </div>
              <div class="payload">
                <img src="./image/ali.png" />
                <span>支付宝支付</span>
              </div>
            </aside>
          </div>
        </div>
        <div class="payqrcode" v-else>
          <div class="paytitle paypub">
            <img src="./image/pay-title-icon.png" />
            <span class="title">在线支付 立即抢购</span>
            <img src="./image/pay-title-icon.png" />
          </div>
          <ul class="payli">
            <li>
              <span class="name">账户名称：</span>
              <span class="label">北京数音未来科技有限公司</span>
            </li>
            <li>
              <span class="name">公司账号：</span>
              <span class="label">110060635018800064856</span>
            </li>
          </ul>
          <ul class="payli">
            <li>
              <span class="name">开户行：</span>
              <span class="label">交通银行股份有限公司北京三元支行</span>
            </li>
            <li>
              <span class="name">联系电话：</span>
              <span class="label">13522352793</span>
            </li>
          </ul>
          <div class="payfom">
            <el-form ref="publicForm" :model="publicForm" :rules="publicRules">
              <el-form-item label="付款回执单：" prop="receipt">
                <SingleImageUpload v-model="publicForm.receipt" accept="image/*"  :isclear="isclear" :isPublic="true"/>
              </el-form-item>
              <el-form-item label="付款备注 ：" prop="remark">
                <div class="remark">
                  <el-input v-model.trim="publicForm.remark" />
                  <el-button @click="handleSubmitPublicInfo">提交</el-button>
                </div>
              </el-form-item>
            </el-form>
            <p class="form-tips">完成线下转账/汇款后，请 <span @click="qimoChatClick">联系客服</span> ，我们将及时为您开通会员权限</p>
          </div>
        </div>
        <div class="payimg">
          <img src="./image/ld.png" />
        </div>
      </div>
    </div>

    <div class="activrule">
      <div class="activ_title">
        <img src="./image/txticon.png" />
        <span>活动规则</span>
        <img src="./image/txticon.png" />
      </div>
      <ul class="activ_content">
        <li>1、活动时间：2023年6月14日-6月23日；</li>
        <li>2、本站会员素材生成作品需激活授权书方可投放；</li>
        <li>3、用户需于PlayList网站活动页面参与会员套餐优惠抢购；</li>
        <li>4、本活动价格为促销价格，支付后不支持退款及转让；</li>
        <li>5、本活动所有解释权归北京数音未来科技有限公司；</li>
        <li>6、如有疑问请 <span @click="qimoChatClick">联系客服</span> 。</li>
      </ul>
     <!-- <p class="activ_content">1、活动时间：2023年6月15日18:00:00-6月30日23:59；2、本站会员素材生成作品需激活授权书方可投放；3、用户需于PlayList网站活动页面参与会员套餐优惠抢购；4、本活动价格为促销价格，支付后不支持退款及转让；5、本活动所有解释权归北京数音未来科技有限公司；6、如有疑问请<span @click="qimoChatClick">联系客服</span>。</p> -->
    </div>
    <!-- 致用户的一封信 -->
    <Letter
      :showLetter="showLetter"
      v-show="showLetter"
      @closeLetterDialog="closeLetter"/>
  </div>
</template>
<script>
import Letter from  '@/views/package/components/Letter';
import VueQrcode from '@/components/VueQrcode';
import CountDown from "./components/CountDown";
import SingleImageUpload from '@/components/UploadImage';
import { payReloadTime } from '@/config';
import { formDateTime } from '@/utils/formDate.js';
import { mapGetters } from 'vuex';
import { send_eventApi } from '@/utils/common.js'
import {
  fetchPackageList,
  fetchOrderPayByWxPay,
  fetchOrderPayByAliPay,
  fetchOrderPayStatusByPackage,
  fetchOrderPayByPublicPay,
  submitPublicPayReceipt
} from '@/api/package';

export default {
  components: { VueQrcode,CountDown,SingleImageUpload,Letter },
  data() {
    return {
      paytype: 'online',
      loading: false,
      startTime: new Date().getTime(),
      endTime: new Date('2023/6/23 23:59:59').getTime(),
      wxPayOrderNo: '',
      aliPayOrderNo: '',
      order_no: '', // 对公转账order_no
      wxPayQrCode: '', // 微信二维码
      aliPayQrCode: '', // 支付宝二维码
      // 定时器
      wxPayTimer: null,
      aliPayOrderTimer: null,
      companyGoodList: [],
      personGoodList: [],
      publicForm:{
        receipt:'',
        remark:''
      },
      isclear: false,
      publicRules: {
        receipt: [
          { required: true, message: '请上传付款回执单', trigger: 'change' }
        ],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      companyIndex: 0,
      personIndex: 0,
      payinfo: null,
      showLetter: false, // 是否显示致用户的一封信
    };
  },
  created() {
    // this.surplusNum = window.localStorage.getItem('surplusNum') || 5;
    this.order_id = this.$route.query.order_id || "";
    this.getPackageList();
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
    surplusNum(){
      return Math.floor(Math.random()*(5-1+1))+1;
    }
  },
  methods: {
    // 获取套餐列表
    async getPackageList(){
      const {data} = await fetchPackageList({
        order_id: '',
        goods_type: 2,
        active_type: 2
      });
      if(data && data[1]){
        this.personGoodList = data[1];
        this.companyGoodList = data[2];

        this.payinfo = data[2][data[2].length-1];
        this.companyIndex = data[2].length;
        this.getPayQrCode();
      }
    },
    // 选择会员类型
    setViptype(item,idx){
      this.companyIndex = idx+1;
      this.personIndex = null;
      this.payinfo = item;
      this.wxPayQrCode = "";
      this.aliPayQrCode = "";
      if(this.paytype == 'online'){
        this.handleClearCountDown('wxPayTimer');
        this.handleClearCountDown('aliPayOrderTimer');
        this.getPayQrCode();
      }
    },
    setPersionViptype(item,idx){
      this.companyIndex = null;
      this.personIndex = idx+1;
      this.payinfo = item;
      this.wxPayQrCode = "";
      this.aliPayQrCode = "";
      if(this.paytype == 'online'){
        this.handleClearCountDown('wxPayTimer');
        this.handleClearCountDown('aliPayOrderTimer');
        this.getPayQrCode();
      }
    },
    // 切换支付方式
    changePay(type){
      this.paytype = type;
      if(type == 'public'){
        this.handleClearCountDown('wxPayTimer');
        this.handleClearCountDown('aliPayOrderTimer');
        this.getPublicOrderNo();
      }else {
        this.getPayQrCode();
      }
    },
    // w微信、支付宝支付
    getPayQrCode(){
      const params = {
        goods_id: this.payinfo.goods_id,
        share_token: window.localStorage.getItem('share_token') || ""
      }
      Promise.all([
        fetchOrderPayByWxPay(params),
        fetchOrderPayByAliPay({...params, qr_width: 156})
      ]).then((res) => {
        this.wxPayOrderNo = res[0].data.order_no;
        this.aliPayOrderNo = res[1].data.order_no;
        this.wxPayQrCode = res[0].data.pay_url;
        this.aliPayQrCode = res[1].data.pay_url;
        this.handleViewOrderPayStatus(this.wxPayOrderNo, 'wxPayTimer');
        this.handleViewOrderPayStatus(this.aliPayOrderNo, 'aliPayOrderTimer');
      })
    },
    // 查看微信、支付宝订单状态
    async handleViewOrderPayStatus(order_no, name) {
      // 进入即刻清除定时器
      try {
        const { data } = await fetchOrderPayStatusByPackage({
          order_no,
          share_token: window.localStorage.getItem('share_token') || ""
        })
        // 判断订单的状态 -1表示订单状态不存在 0表示订单支付成功
        if (data.status === -1) {
          // 添加定时器
          this[name] = setTimeout(() => {
            this.handleViewOrderPayStatus(order_no, name)
          }, payReloadTime)
          return
        } else if (data.status === 0) {
          this.handleClearCountDown('wxPayTimer');
          this.handleClearCountDown('aliPayOrderTimer');
          // window.localStorage.setItem('surplusNum', this.surplusNum < 2 ? 1 : this.surplusNum -1);
          this.sendEventapi();
          this.showLetter = true;
          this.$store.dispatch('user/getUserInfo');
        }
        this.handleClearCountDown(name)
      } catch (e) {
        this.handleClearCountDown(name)
      }
    },
    // 获取对公订单号
    async getPublicOrderNo(){
      const { data } = await fetchOrderPayByPublicPay({
        goods_id: this.payinfo.goods_id,
        order_id: this.order_id || '',
        share_token: window.localStorage.getItem('share_token') || ""
      });
      this.order_no = data.order_no;
    },
    // 对公转账提交
    handleSubmitPublicInfo() {
      this.$refs.publicForm.validate(async (valid) => {
        if (!valid) return
        const { receipt: receipt_path, remark } = this.publicForm;
        const params = {
          receipt_path,
          remark,
          order_no:this.order_no
        }
        try {
          await submitPublicPayReceipt(params);
          this.$message.success('提交成功');
          this.sendEventapi();
          this.$refs.publicForm && this.$refs.publicForm.resetFields(); // 清空付款回执单跟付款备注
          this.isclear = true;
          this.showLetter = true;
        } catch (e) {
          console.log(e)
        }
      })
    },
    // 客服
    qimoChatClick(){
      window.qimoChatClick();
    },
    // 清楚定时器
    handleClearCountDown(name) {
      const timer = this[name]
      if (timer) {
        clearTimeout(timer)
        this[name] = null
      }
    },
    // 埋点
    sendEventapi() {
      const obj = {
        package_name: this.payinfo.name,
        package_type: '企业',
        pay_type: this.paytype == 'online' ? '在线支付（支付宝/微信）' : '对公转账',
        op_date: formDateTime(),
        m_name: '',
        mid: '',
        reg_date: this.payinfo.create_at,
        user_name: this.userInfo.name,
        user_id: this.userInfo.user_id
      }
      send_eventApi('e_pay', obj)
    },
    closeLetter(){
      this.showLetter = false;
    },
  },
  beforeDestroy() {
    this.handleClearCountDown('wxPayTimer')
    this.handleClearCountDown('aliPayOrderTimer')
  },
};
</script>
<style lang="scss" scoped>
*{
  padding: 0;
  margin: 0;
  font-style: normal;
}
img{
  display: inline-block;
  height: 100%;
}
.camping-container {
  width: 100%;
  min-height: calc(100vh - 350px);
  margin-top: 60px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  user-select: none;
  background: url("./image/bg1.png") no-repeat;
  background-size: 100% 100%;
  .page-title{
    height: 42px;
    display: flex;
    margin: 70px 32px 35px 32px;
    align-items: center;
    justify-content: space-between;
    img{
      height: 24px;
    }
    span{
      display: inline-block;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 42px;
      letter-spacing: 10px;
      margin: 0 32px;
    }
  }
  .set-title {
    display: flex;
    margin: 50px 32px 35px 32px;
    align-items: center;
    justify-content: space-between;
    span{
      margin: 0 32px;
      font-size: 20px;
      font-weight: 600;
      color: #FFFFFF;
    }
    img{
      width: 16px;
      height: 16px;
    }
  }
  .page-sub-title{
    height: 115px;
    position: relative;
    .star{
      width: 71px;
      height: 79px;
      position: absolute;
      top: -20px;
      left: -88px;
    }
    .asideicon{
      position: absolute;
      top: 73px;
      left: -145px;
    }
  }
  .downbox{
    margin-top: 20px;
    width: 560px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("./image/downbg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .downtext,.countdown{
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 25px;
      letter-spacing: 5px;
    }
    img{
      position: absolute;
      width: 52px;
      height: 58px;
      right: 35px;
      top: -26px;
    }
  }
  .remaining{
    margin-top: 40px;
    //width: 274px;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 50px;
    display: flex;
    align-items: center;
    span{
      display: inline-block;
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 50px;
    }
  }
  .paybox,.paytype{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .payitem{
      width: 380px;
      height: 360px;
      border-radius: 30px;
      border: 1px solid #fff;
      margin: 0 15px;
      padding: 12px;
      position: relative;
      background: rgba(255,146,110,0.5);
      box-shadow: 0px 0px 12px 0px rgba(157,31,30,0.3);
      filter: blur(0.3px);
      -webkit-filter: none;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      &:first-child{
        margin-left: 0;
      }
    }
    .paylist{
      width: 100%;
      height: 100%;
      background: url("./image/paybg.png") no-repeat;
      background-size: 100% 100%;
      //box-shadow: 0px 0px 12px 0px rgba(157,31,30,0.3);
      border-radius: 30px;
      position: relative;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .payname{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 74px;
        img{
          height: 21px;
          margin-top: 15px;
        }
      }
      .paystar{
        position: absolute;
        left: -4px;
        top: 12px;
        width: 102px;
        height: 102px;
      }
      .paystar2{
        position: absolute;
        top: 10px;
        left: 90px;
        width: 56px;
        height: 56px;
      }
      .paystar3{
        position: absolute;
        top: 0;
        left: 0;
        width: 130px;
        height: 130px;
      }
      .tipbox{
        width: 49px;
        height: 37px;
        background: url("./image/mobile/tip.png") no-repeat;
        background-size: 100% 100%;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 30px;
        text-align: center;
        position: absolute;
        right: 22px;
        top: -9px;
      }
      .old-price{
        margin-top: 40px;
        //padding-left: 121px;
        display: flex;
        align-items: center;
        .name{
          display: inline-block;
          height: 14px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0,0,0,0.25);
          line-height: 14px;
        }
        .price{
          display: inline-block;
          height: 18px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(239,73,66,0.75);
          line-height: 18px;
          text-decoration:line-through;
        }
      }
      .current_price{
        display: flex;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        // color: rgba(0,0,0,0.85);
        line-height: 60px;
        color: #F60002;
        // background: linear-gradient(135deg, #FF6D62 0%, #F60002 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        align-items: baseline;
        //padding-left: 92px;
        margin-top: 14px;
        .icon{
          font-size: 28px;
        }
      }
      .downloadcount{
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.5);
        line-height: 20px;
        text-align: center;
        margin-top: 22px;
      }
      .daydown{
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0,0,0,0.5);
        line-height: 20px;
        text-align: center;
        margin-top: 8px;
      }
      .youhui{
        margin-top: 30px;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(239,73,66,0.75);
        line-height: 17px;
        letter-spacing: 2px;
      }
    }
    .defaultActive{
      border: 3px solid #fff;
      box-shadow: 0px 10px 20px 0px rgba(255,43,30,0.7);
    }
    .wxpay,.alipay{
      padding: 15px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #4D4D4F;
      margin: 0 5px;
    }
  }
  .paytype{
    margin-top: 50px;
    width: 1200px;
    height: 385px;
    background: rgba(255,146,110,0.5);
    box-shadow: 0px 10px 20px 0px rgba(255,43,30,0.3);
    border-radius: 30px;
    border: 1px solid #fff;
    filter: blur(0.3px);
    padding: 24px;
    box-sizing: border-box;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: 29px;
      height: 22px;
      display: block;
      z-index: 1;
      top: -21px;
      transition: all .3s ease;
      background: url('./image/arrow.png') no-repeat;
      background-size: 100% 100%;
    }
    .payconter{
      width: 100%;
      height: 100%;
      background: url("./image/payconter.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: space-between;
    }
    .paytabs{
      width: 116px;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 120px;
      padding-top: 82px;
      .pay_comp{
        width: 116px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid rgba(0,0,0,0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img{
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
        label{
          display: inline-block;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0,0,0,0.5);
          line-height: 20px;
          cursor: pointer;
        }
        &:last-child{
          margin-top: 16px;
        }
      }
      .active{
        border: 1px solid #E52A0D;
        label{
          color: #E52A0D;
        }
      }
    }
    .payqrcode{
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 95px;
      .paytitle{
        padding: 24px 0 30px 0;
        box-sizing: border-box;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #F60002;
        line-height: 28px;
        // background: linear-gradient(135deg, #FF6D62 0%, #F60002 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        text-align: center;
        img{
          width: 16px;
          height: 16px;
          margin: 0 32px;
        }
      }
      .paypub{
        padding-bottom: 20px;
      }
      .qrcodebox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .qrcode{
          width: 180px;
          height: 180px;
          border: 1px solid rgba(0,0,0,0.25);
          padding: 12px;
          box-sizing: border-box;
        }
        .payload{
          width: 100%;
          margin-top: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 16px;
            height: 16px;
          }
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 16px;
            margin-left: 8px;
          }
        }
      }
      .payli{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        user-select: text;
        li{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 28px;
          text-align: left;
          &:first-child{
            width: 305px;
            overflow: hidden;
          }
        }
        .name{
          color: rgba(0,0,0,0.5);
        }
        .label{
          color: #000000;
        }
      }
      .payfom{
        /deep/{
          .upload-container{
            height: 90px;
            margin-top: 10px;
            .image-uploader,.el-upload{
              height: 100%;
            }
            .el-upload-dragger{
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background: transparent;
              border-color: #fff;
              padding: 0 30px;
              box-sizing: border-box;
            }
            .el-icon-upload{
              margin: 0;
            }
            .el-upload__text{
              line-height: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0,0,0,0.5);
              margin-top: 10px;
            }
          }
          .el-form-item{
            display: flex;
            margin-bottom: 24px;
            &:last-child{
              margin-bottom: 0;
              .el-form-item__error{
                padding-left: 10px;
              }
            }
          }
          .el-form-item__error{
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #E52A0D;
            line-height: 14px;
          }
          .el-form-item__label{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 28px;
            color: rgba(0,0,0,0.5);
          }
          .el-form-item__content{
            flex: 1;
          }
          .el-button{
            width: 96px;
            height: 100%;
            background: #E52A0D;
            border-radius: 5px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 20px;
            margin-left: 24px;
          }
          .el-input,.el-input__inner{
            height: 100%;
            line-height: 32px;
          }
        }
        .remark{
          width: 100%;
          height: 32px;
          display: flex;
          padding-left: 10px;
        }
        .form-tips{
          margin-top: 20px;
          text-align: right;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0,0,0,0.5);
          line-height: 17px;
          span{
            color: #E52A0D;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    .payimg{
      width: 201px;
      height: 100%;
      display: flex;
      align-items: flex-end;
      margin-left: 60px;
      img{
        height: 202px;
      }
    }
  }

  // $max: 3;

  // @for $i from 0 through $max {
  //   .choose-index-#{$i} {
  //     &:after {
  //       left: (190px + ($i * 406px)) !important;
  //     }
  //   }
  // }
  .choose-index-0 {
    &:after {
     left: 386px;
    }
  }
  .choose-index-1 {
    &:after {
     left: 798px;
    }
  }
  .activrule{
    width: 1080px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .activ_title{
      img{
        width: 16px;
        height: 16px;
        margin: 0 32px;
      }
      span{
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 28px;
      }
    }
    .activ_content{
      margin-top: 20px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      span{
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
